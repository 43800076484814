// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/Users/kate/personal/portfolio/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("/Users/kate/personal/portfolio/src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-classhook-js": () => import("/Users/kate/personal/portfolio/src/pages/classhook.js" /* webpackChunkName: "component---src-pages-classhook-js" */),
  "component---src-pages-design-js": () => import("/Users/kate/personal/portfolio/src/pages/design.js" /* webpackChunkName: "component---src-pages-design-js" */),
  "component---src-pages-employstream-js": () => import("/Users/kate/personal/portfolio/src/pages/employstream.js" /* webpackChunkName: "component---src-pages-employstream-js" */),
  "component---src-pages-entelo-conveyiq-js": () => import("/Users/kate/personal/portfolio/src/pages/entelo-conveyiq.js" /* webpackChunkName: "component---src-pages-entelo-conveyiq-js" */),
  "component---src-pages-entelo-js": () => import("/Users/kate/personal/portfolio/src/pages/entelo.js" /* webpackChunkName: "component---src-pages-entelo-js" */),
  "component---src-pages-hsad-js": () => import("/Users/kate/personal/portfolio/src/pages/hsad.js" /* webpackChunkName: "component---src-pages-hsad-js" */),
  "component---src-pages-index-js": () => import("/Users/kate/personal/portfolio/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-page-2-js": () => import("/Users/kate/personal/portfolio/src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-password-js": () => import("/Users/kate/personal/portfolio/src/pages/password.js" /* webpackChunkName: "component---src-pages-password-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/Users/kate/personal/portfolio/.cache/data.json")

